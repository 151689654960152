import React from 'react'
import Multimedia from '../pagesTemplates/multimedia'
import gatsbyi18Context from '../contexts/gatsby-i18n-context.js'

export default (props) => {
  const { pageContext } = props
  const { langKey: language } = pageContext

  const data = {
    language,
  }

  return (
    <gatsbyi18Context.Provider value={data}>
      <Multimedia {...props} />
    </gatsbyi18Context.Provider>
  )
}

export const query = graphql`
  query {
    latestMultimedia: allWordpressWpMultimedia(
      sort: { fields: date, order: DESC }
      limit: 4
      filter: {
        polylang_current_lang: {
          eq: "en_US"
        }
      }
    ) {
      nodes {
        title
        slug
        multimedia_sections
        thumbnail: featured_media {
          localFile {
            childImageSharp {
              fixed (width: 1400) {
                src
              }
            }
          }
        }
      }
    }

    sections: allWordpressWpMultimediaSections {
      nodes {
        wordpressId: wordpress_id
        slug
        name
        count
      }
    }
  }
`
